.loading-splash {
  .loader-wrapper {
    background-color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 200px;
    height: 200px;
    margin: -100px 0 0 -100px;
    // border-radius: 50%;
    // border: 3px solid transparent;
    // border-top-color: #14d1b2;
    // -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    // animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
}
