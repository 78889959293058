.chart-token-menu {
  .tokenInfo {
    position: absolute;
    top: 6rem;
    right: unset;
    left: 0;
    width: 360px;
    background: #0a0a0a;
    border: 1px solid rgba(255, 255, 255, 0.16);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(24px);
    z-index: 313121;
    border-radius: 8px;
    .search {
      height: 48px;
      padding: 0 10px 0 16px;
      background: rgba(0, 0, 0, 0.2);
    }
    .typeTab {
      padding: 8px;
      .tabItem {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
      }
      .tabItemActive {
        background: rgba(10, 213, 189, 0.2);
        color: #14d1b2;
      }
    }
    .token_th {
      height: 36px;
      padding: 0 16px;
    }

    .change {
      width: 90px;
    }

    .pair {
      width: 120px;
    }

    .tokenList {
      height: 280px;
      overflow: auto;
      .tokenItem {
        height: 48px;
        padding: 0 16px;
        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
  .search_all {
    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      font-family: "Gilroy";
    }
  }
}

.tokenList::-webkit-scrollbar {
  width: 4px;
  background: rgba(45, 34, 104, 0.4);
}

.tokenList::-webkit-scrollbar-track {
  background: 0 0;
}

.tokenList::-webkit-scrollbar-thumb {
  background: #5e58db;
  border-radius: 16px;
}

.tokenList::-webkit-scrollbar-thumb:hover {
  background: #5e58db;
}
