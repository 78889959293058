.chart-token-selector {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.chart-token-selector--current {
    font-size: 1.8rem;
    font-weight: 700;
    font-weight: 700;
    margin-right: 1.1rem;
    color: #EEF2F9;
    display: flex;
    align-items: center;
    gap: 8px;
}

.ExchangeChart-additional-info{
    height: 56px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding:  0px 18px 0 16px;
    font-size: 12px;
    font-weight: 500;
}


.chart-token-selector--current  >span {
    color: rgba(238, 242, 249, 0.5);
}

.chart-token-selector--current>em {
    font-size: 1.5rem;
    color: rgba(238, 242, 249, 0.5);
}

.chart-token-menu-items.menu-items {
    width: 15.8rem;
    top: 6rem;
    right: unset;
    left: 0;
}
