.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.Checkbox .Checkbox-icon {
  font-size: 1.25rem;
  color: #14D1B2;
  margin-bottom: 0;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive rect {
  fill: rgb(0 0 0 / 40%);
}

.Checkbox-label {
  flex: 1;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5rem;
  color: #EEF2F9;
}

.isChecked {
  color: rgba(238, 242, 249, 0.5);
}