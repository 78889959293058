.Tooltip {
  position: relative;
}

.Tooltip-popup {
  position: absolute;
  z-index: 1000;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto; */
}

.popups {
  border-radius: 4px;
  padding: 8px 16px;
  min-width: 32rem;
  text-align: left;
  color: rgba(238, 242, 249, 0.5);
  background: #1A191E;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip-popup.right {
  right: 0;
  top: 100%;
  transform: translateY(0.4rem);
  bottom: 100%;
}

.Tooltip-popup.left {
  left: 0;
  top: 100%;
  transform: translateY(0.4rem);
}

.Tooltip-popup.center-bottom {
  left: 50%;
  transform: translateY(0.8rem) translateX(-50%);
  top: 100%;
}

.Tooltip-popup.center-top {
  left: 50%;
  transform: translateY(-0.8rem) translateX(-50%);
  bottom: 100%;
}

.Tooltip-handle {
  cursor: help;
  position: relative;
  display: inline-flex;
  /* text-decoration: underline; */
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-decoration-color: rgba(255, 255, 255, 0.6);
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

.Tooltip-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 0.5rem 0;
}

.Tooltip-row {
  margin: 0 0 0.5rem 0;
}

.tooltipsBorder {
  padding: 16px 8px;
  border-radius: "4px";
  border: 1px dashed rgba(255, 255, 255, 0.16);
  background: #1a191e;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.4);
}

@media (max-width: 680px) {
  .popups {
    min-width: 26rem;
    max-width: 80vw;
    padding: 12px 8px;
  }

  .Tooltip-popup.center-bottom {
    transform: translateY(0.8rem) translateX(-60%);
  }

  .Tooltip-popup.center-top {
    transform: translateY(-0.8rem) translateX(-60%);
  }
}