* {
  font-family: "Gilroy", "Inter", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

button {
  user-select: none;
}

html {
  font-size: 12px;
  font-family: "Gilroy";
  line-height: 150%;
  font-variant: none;
  font-smooth: always;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body,
#root {
  min-height: 100vh;
}
